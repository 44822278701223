
body {
  margin: 0;
  padding: 0 0.5em;
  background-color: antiquewhite;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav--bar {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #504e4e;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  }

.nav--text {
  text-decoration: none;
  color: #000;
}

.header--block {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 0 0;
  background-color: antiquewhite;
}



.test--border {
  border-color: #363333;
  border-width: 2px;
  border-style: solid;
}


.introduction {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: rgb(75, 69, 69);
  animation: fade-out 1000ms;
  width: 90vw;
  max-width: 850px;
}

.hello--text {
  font-size: clamp(0.75em, 2vw, 2em)
}

.darkgray--text {
  color: #363333;
  font-size: clamp(1em, 5vw, 4em);
}

.white--background {
  animation: fade-out 3000ms;
  background-color: #363333;
  padding: 0px;
  margin-bottom: 10px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

@media (min-width: 1100px) {
  body {
    padding: 0% 0%;
  }

  .white--background {
    animation: fade-out 3000ms;
    background-color: #363333;
    padding: 0px;
    margin-bottom: 10px;
    height: 186px;
    width: 155px; 
  }
  
  .header--block{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .about--section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    
  }
 
  .skills--section{
    height: 100vh;
    
  }
  .skills--block {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: space-evenly;
    width: 100vw;
    background-color: #000;
    height: 30vh;
    padding: 2em 0 2em 0;

  }
  .current--projects {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: space-evenly;
    width: 100vw;
    padding: 1px 0px;
    height: 70vh;
}
  .projects--section {
    height: 100vh;
    
  }

  .projects--dimensions {
    z-index: 1;
    min-height: 228px;
    min-width: 312px;
  }
  

  .contact--section {
    height: 100vh;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-evenly;
    align-items: space-evenly;
  }
  .contact--form {
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 45vw;
    padding: 2em 0 2em 0;
    border-radius: 2rem;
  }

  .contact--links {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
    min-height: 20vh;
  }

  .contact--icon--font {
    font-size: 7em;
  }
  .img-hover-zoom {
    height: 12em; 
    overflow: hidden; 
  }
  .img-hover-zoom img {
    transition: transform .5s ease;
  }
  
  .img-hover-zoom:hover img {
    transform: scale(1.5);
  }
  .img-hover-zoom--brightness img {
    transition: transform 2s, filter 1.5s ease-in-out;
    transform-origin: center center;
    filter: brightness(50%);
  }
  
  .img-hover-zoom--brightness:hover img {
    filter: brightness(100%);
    transform: scale(1.3);
  }
}

.about--left {
  max-width: 70vh;
}

.about--titles {
  font-size: clamp(1em, 2vw, 3em);
}

p, li {
  font-size: clamp(0.75em, 2vw, 1em);
}

.fun--facts--box {
  min-width: 20rem;
}

.fun--facts {
  max-width: 18em;
}
.white--text {
  color: white;
}

img {
  display: block;
  max-width: 100%; 
}

.photo--container {
  height: 250px;
  max-width: 250px;
}


.current--projects {
  gap: 1em;
  padding: 2em 0 2em 0;

}

.projects--section {
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: antiquewhite;
}

.projects--title {
  font-size: clamp(2em, 2.5vw, 3em);
  color: #363333;
  text-align: center;
}

.project--name {
  color: #363333;

}

.projects--dimensions {
  height: 12em;
  width: 17em;
  max-width: auto;
  margin-bottom: 1em;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.project-preview-img{
  display: block;
  max-width: 100%; 
}


.skills--section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(26, 24, 24);
  padding: 2em 0 2em 0; 
}

.skills--header {
  margin-bottom: 1em;
  text-align: center;
}

.skills--block {
  background-color: rgb(26, 24, 24);
  margin: 2em 0 2em 0;
  
}

.aligned {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.white--text{
  color: white;
}

.react--icon {
  width: 200px;
  height: 200px;
}

.fa-js {
  color: #e7b549;
}

.fa-html5 {
  color: orangered
}

.fa-css3 {
  color: lightskyblue;
}

.icon--font {
  font-size: 12em;
}

.fa-wordpress {
  
  color: lightblue;
}



.contact--section {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: antiquewhite;
  
}

.contact--form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  background-color: rgb(26, 24, 24);
  width: 92.5vw;
  
  
}

.contact--links {
  display: flex;
  justify-content: space-evenly;
  width: 92.5vw;
  
  padding: 1em 0;
}

.contact--icon--font {
  font-size: 5em;
  color: rgb(88, 85, 85);
}



.submit--btn {
  margin-bottom: 1em;
}


.credits {
  padding-top: 9vh;
}


@keyframes fade-out {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.introduction {
  animation: fade-out 2000ms;
}

.about--section {
  padding: 1rem 3rem;
  background-color: rgb(26, 24, 24);;
  
}



#tenzies--body {
  box-sizing: content-box;
  margin: 0;
  background-color: #e4bf4c;
  font-family: 'Karla', sans-serif;
  
}


@media (min-width: 700px) {
  #tenzies--body {
  
    padding: 5% 25%  12% 25%;
    
  }
}
.tenzies--center {
  background-color: #d8b422;
  margin: auto;
  height: 600px;
  max-width: 500px;
  border-radius: 5px;
  padding: 20px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.tenzies--title {
  font-size: 40px;
  margin: 0;
  color: #3e2f6f
}

.tenzies--instructions {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  margin-top: 0;
  text-align: center;
  color: #3e2f6f
}

.dice-container {
  display: grid;
  grid-template: auto auto / repeat(5, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}

.die-face {
  height: 50px;
  width: 50px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.die-num {
  font-size: 2rem;
  color: #3e2f6f;
}

.roll-dice {
  height: 50px;
  width: 150px;
  border: none;
  border-radius: 6px;
  background-color: #b37231;
  color: white;
  font-size: 1.2rem;
  font-family: 'Karla', sans-serif;
  cursor: pointer;
}

.roll-dice:focus {
  outline: none;
}

.roll-dice:active {
  box-shadow: inset 5px 5px 10px -3px rgba(0, 0, 0, 0.7);
}

.error--page {
  display: flex;
  justify-content: center;
}


.ql-editor p,
.ql-editor.ql-blank::before {
  font-size: 1.3em;
  font-weight: 100;
}

.pane {
  overflow-y: auto;
  padding: 10px;
}

.sidebar {
  width: 20vw;
  height: 100vh;
  
}

.sidebar--note {
   display: flex; 
}

.sidebar--header {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.sidebar--header > h3 {
  font-size: 1.75rem;
}

.new-note {
  cursor: pointer;
  background-color: #4A4E74;
  border: none;
  color: white;
  border-radius: 3px;
  height: 30px;
  width: 30px;
}

.first-note {
  cursor: pointer;
  background-color: #4A4E74;
  border: none;
  color: white;
  border-radius: 3px;
  padding: 1rem;
}

.editor {
  width: 80vw;
  height: 100vh;
  
}

.title {
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.title--delete {
  display: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.title:hover .title--delete {
  display: block;
}

.note-summary:not(:last-of-type) {
  border-bottom: 1px solid lightgray;
}

.text-snippet {
  padding-inline: 5px;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: #4A4E74;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected-note {
  background-color: #4A4E74;
}

.selected-note .text-snippet {
  color: white;
  font-weight: 700;
}

.gutter {
  background-color: #eee;

  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
}

.gutter.gutter-horizontal:hover {
  cursor: col-resize;
}

.split {
  display: flex;
}

.no-notes {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
}
.notes--message{
  color: #363333;
  padding-left: 10%;
  padding-right: 10%;
}
.delete-btn {
  display: none;
  background: none;
  border: none;
}

.title:hover > .delete-btn {
  display: block;
}

.trash-icon {
  cursor: pointer;
}

.gg-trash {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 10px;
  height: 12px;
  border: 2px solid transparent;
  box-shadow:
      0 0 0 2px,
      inset -2px 0 0,
      inset 2px 0 0;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  margin-top: 4px
}

.gg-trash::after,
.gg-trash::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute
}

.gg-trash::after {
  background: currentColor;
  border-radius: 3px;
  width: 16px;
  height: 2px;
  top: -4px;
  left: -5px
}

.gg-trash::before {
  width: 10px;
  height: 4px;
  border: 2px solid;
  border-bottom: transparent;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  top: -7px;
  left: -2px
} 

.notes--body * {
  box-sizing: border-box;
}
.notes--body {
  margin: 0;
  padding: 0;
  font-family: 'Karla', sans-serif;
}

.meme--main {
  padding: 36px;
}

.meme--header {
  display: flex;
  align-items: center;
  height: 65px;
  background: linear-gradient(90deg, #2e2e2e 1.18%, #979199 100%);
  color: white;
  padding: 20px;
}

.meme--header--image {
  height: 100vh;
  margin-right: 6px;
}

.meme--header--title {
  font-size: 1.25rem;
  margin-right: auto;
}

.meme--form--input {
  font-family: "Karla", sans-serif;
  border-radius: 5px;
  border: 1px solid #D5D4D8;
  text-indent: 5px;
}

.meme--form--button {
  
  font-family: "Karla", sans-serif;
  border-radius: 5px;
  background: linear-gradient(90.41deg, #979199 1.14%, #2e2e2e 100%);
  color: white;
  border: none;
  cursor: pointer;
}

.meme--text--alignment {
  display: flex;
  flex-direction: column;
}

.drag--text--placement {
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;
}

.meme--text {
  font-size: clamp(.75em, 2vw, 3em);
  width: 80%;
  margin: 15px 0;
  padding: 0 5px;
  font-family: "Karla", sans-serif;
  
  cursor: pointer;
  color: rgb(245, 241, 241);
  letter-spacing: 1px;
  text-shadow:
    1px 1px 0 #000,
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    0 1px 0 #000,
    1px 0 0 #000,
    0 -1px 0 #000,
    -1px 0 0 #000,
    1px 1px 2.5px #000;
}

.back--home{
  cursor: pointer;
}

@media (min-width: 1100px) {

 .meme--text {
  text-shadow:
  2px 2px 0 #000,
  -2px -2px 0 #000,
  2px -2px 0 #000,
  -2px 2px 0 #000,
  0 2px 0 #000,
  2px 0 0 #000,
  0 -2px 0 #000,
  -2px 0 0 #000,
  2px 2px 5px #000;
 }

 .meme--img--container {
   max-height: 900px;
   max-width: 1100px;
   
 }

 .meme--image {
   max-height: 900px;
   max-width: 1100px;
   
 }

 .meme {
   height: 100vh;
 }
 .meme--form--input {
   width: 50vw;
 }
 .meme--form--button {
   width: 10vw;
 }
}


  .meme--form {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 80vw;
  }

  .meme--form--button {
    margin: 15px;
  }

  .meme {
    width: 90vw;
    
    border-radius: 1px;
    margin-left: auto;
    margin-right: auto;
    
    text-align: center;
  }
  .meme--image {
    
    max-width: 100%;
    height: auto;
  }
 

